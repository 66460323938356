<template>
<p class="receiving-qr">
    <span class="receiving-qr-title">
        <Svgs name="iconerweima" classes="receiving-qr-title-icon" />
        {{itemInfo.title}}
    </span><br>
    {{itemInfo.content}}

    <a class="receiving-qr-btn" @click="toQRDetail()">
        <Svgs name="iconjiantou2" color="#ffffff" />
    </a>
</p>
</template>

<script>
export default {
    name: 'ReceivingQR',
    components: {},
    props: {
        itemInfo: Object
    },
    methods: {
        toQRDetail() {
            this.$router.push({
                name: 'ReceivingQRDetail',
                params: {
                    itemInfo: this.itemInfo
                }
            })
        }
    }
}
</script>

<style lang="scss">
.receiving-qr {
    position: relative;
    margin-top: .08rem;
    width: calc(100% - .4rem);
    background: rgba(255, 255, 255, 1);
    box-shadow: 0 5px 18px 0 rgba(8, 37, 105, 0.1);
    border-radius: .04rem;
    padding: .2rem;
    box-sizing: content-box;

    font-size:13px;
    font-family:PingFang SC;
    font-weight:500;
    color:rgba(19,22,27,1);
    line-height:14px;

    .receiving-qr-title {
        color:rgba(144,147,154,1);
        /*flex 布局*/
        display: flex;
        /*实现垂直居中*/
        align-items: center;

        .receiving-qr-title-icon {
            margin-right: 5px;
            width: .13rem !important;
            height: .13rem !important;
        }
    }
    .receiving-qr-btn {
        position: absolute;
        line-height: .54rem;
        text-align: center;
        top: .2rem;
        right: .2rem;
        width: .42rem;
        height: .42rem;
        background-color: var(--themeColor);
        box-shadow:1px 1px 1px var(--shadowColor);
        border-radius: 50%;
    }
}
</style>