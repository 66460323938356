<template>
  <div class="hot-line">
    <p class="hot-line-title">{{ itemInfo.title }}</p>
    <p class="hot-line-content" v-html="itemInfo.content"></p>
    <a v-if="itemInfo.call" class="hot-line-call" @click="showisVisible">
      <Svgs name="icondianhua-" color="#ffffff" />
    </a>
    <Popup ref="pop" :clickClose="true">
      <div slot="popContent">
        <div class="cateGList">
          <p>请选择会议服务热线</p>
          <a
            :href="'tel:' + call.number"
            v-for="(call, index) in itemInfo.call"
            :key="index"
            >{{ call.number }}</a
          >
        </div>
      </div></Popup
    >
  </div>
</template>

<script>
export default {
  name: "HotLine",
  components: {},
  props: {
    itemInfo: Object,
  },
  data() {
    return {};
  },
  methods: {
    showisVisible() {
      this.$refs.pop.changePop();
    },
  },
};
</script>

<style lang="scss">
.cateGList {
  background: #fff;
  margin-top: -5rem;
  width: 70%;
  margin-left: 15%;
  padding: 0.2rem;
  box-sizing: border-box;
  border-radius: 2px;
}
.cateGList p{
  text-align: center;
  margin-bottom: 0.2rem;
}
.cateGList a {
  width: 100%;
  display: block;
  color: #000;
  font-size: 0.18rem;
  text-decoration: none;
  text-align: center;
  background-color: var(--opacityColor);
  color: var(--themeColor);
  padding: 0.04rem 0;
  margin-bottom: 0.1rem;
}
.cateGList a:first-child {
  margin-bottom: 10px;
  padding-bottom: 10px;
  border-bottom: 1px dashed #999;
}
.hot-line {
  position: relative;
  width: calc(100% - 0.2rem);
  background: rgba(255, 255, 255, 1);
  box-shadow: 0 5px 18px 0 rgba(8, 37, 105, 0.1);
  border-radius: 0.04rem;
  padding: 0.2rem .1rem;
  box-sizing: content-box;
  .hot-line-title {
    font-size: 0.15rem;
    font-family: PingFang SC;
    font-weight: 500;
    color: rgba(19, 22, 27, 1);
    line-height: 0.18rem;
  }
  .hot-line-content {
    margin-top: 0.16rem;
    font-size: 0.13rem;
    font-family: PingFang SC;
    font-weight: 400;
    color: rgba(19, 22, 27, 1);
    line-height: 0.16rem;
  }
  .hot-line-call {
    position: absolute;
    text-align: center;
    line-height: 0.54rem;
    top: 0.4rem;
    right: 0.2rem;
    width: 0.42rem;
    height: 0.42rem;
    background-color: var(--themeColor);
    box-shadow: 1px 1px 1px var(--shadowColor);
    border-radius: 50%;
  }
}
</style>