<template>
    <div>
        <div class="customer-service">
            <!-- <Header back=true >
            <template v-slot:headText>联系客服</template>
        </Header> -->
        <div class="hearderBox">
            <span @click="backPath">
                <template v-if="isWxWork">返回</template>
                <Svgs v-else name="iconfanhui" color="#000" />
            </span> 
            开票信息
        </div>
            <!-- <PublicTitle @back-call="backPath" title="开票信息" /> -->
            <div class="customer-service-content">
                <component v-for="(item, index) in componentList" :key="index" :is="item.componentName"
                    :itemInfo="item"></component>

                <Skeleton v-if="isFirst" :skList="skList"></Skeleton>
            </div>
        </div>
    </div>
</template>

<script>
import HotLine from "./customerService/HotLine";
import ReceivingQR from "./customerService/ReceivingQR";
import { SmartStorage } from 'smart-core-util'
export default {
    name: "invoiceInfo",
    components: {
        HotLine,
        ReceivingQR
    },
    data() {
        return {
            isWxWork: window.navigator.userAgent.toLowerCase().indexOf('wxwork') !== -1,
            isFirst: true,
            componentList: [],
            skList: [
                {
                    width: "100%",
                    height: "1.2rem",
                    mt: ".3rem",
                },
                {
                    width: "100%",
                    height: "1rem",
                    mt: ".3rem",
                },
                {
                    width: "50%",
                    height: ".3rem",
                    mt: ".3rem",
                },
                {
                    width: "100%",
                    height: ".3rem",
                },
                {
                    width: "70%",
                    height: ".3rem",
                    mt: ".3rem",
                },
                {
                    width: "100%",
                    height: ".3rem",
                },
            ],
        };
    },
    created() {
        this.getCustomerServiceInfo();
    },
    methods: {
        async getCustomerServiceInfo() {
            let tenant = this.$cookies.get("tenant") || SmartStorage.get("tenant");
            console.log(this.$cookies.get("tenant"))
            console.log(SmartStorage.get("tenant"))
            const params = {
                collection: "cfg-ihelper-wiki",
                filter: {
                    tenantCode: tenant,
                },
                projection: {
                    invoiceInfo: 1,
                },
            };
            let _this = this;
            this.services.GetSettings(params).then(function (res) {
                if (res.success) {
                    _this.isFirst = false;
                    _this.componentList = res.content.invoiceInfo;
                }
            });
        },
        backPath() {
            this.iJsBridge.close();
        },
    },
}
</script>

<style lang="scss" scoped>
    .customer-service {
        // padding-top: 1rem;
        .customer-service-content {
            padding: 0.1rem;
        }
    }
    </style>